
import TableRootEntities from "@/components/TableRootEntities.vue";
import { get, post } from "@/api/axios";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TableRootEntities
  }
})
export default class Employees extends Vue {
  addNewEmployeeMode = 0;
  employees: any[] = [];
  employee = {
    firstname: "",
    lastname: ""
  }
  newEmployeeLastnameFirstname: string = "";

  declare config: { entities: any[] }; // TODO

  caption(item: any /* todo */) {
    if (!item) return "";
    return item.lastname;
  }

  mounted() {
    get("/employees")
      .then((resp) => {
        const result = resp.data;
        console.log(result);
        this.employees = result;
      });
  }

  addEmployee(item: any /* todo */) {
    if (!item) {
      console.error(`item is undefined`);
      return;
    }
    if (typeof item.title !== "string") {
      console.error(`item.title is not string`);
      return;
    }
    this.newEmployeeLastnameFirstname = item.title;
    if (this.newEmployeeLastnameFirstname.split(" ").length < 2) {
      console.log("Incorrent newEmployeeLastnameFirstname");
      return;
    }
    this.employee.firstname = this.newEmployeeLastnameFirstname.split(" ")[0];
    this.employee.lastname = this.newEmployeeLastnameFirstname.split(" ")[1];

    post("/employees", this.employee)
      .then((response) => {
        const result = response.data;
        console.log("result of addEmployee: ", result);
        if (result.details == null) {
          this.employees.push(result);
        }
        this.newEmployeeLastnameFirstname = "";
        this.addNewEmployeeMode = 0;
      });
  }
}
